import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { defaultLoggedOutUser } from "../../constants"
import { colors } from "../../styles"
import { User, UserData } from "../../types"
import { AuthContext } from "../auth-context"
import * as T from "./../typography"
import {
  CloseOutlineStyled,
  CogStyled,
  HamburgerHeader,
  HeaderPageLink,
  HeaderPagesLinks,
  LeftMenu,
  LogoStyled,
  LogoutCircleRStyled,
  MobileHamburgerMenuItem,
  MobileHamburgerMenuWrapper,
  MobileLogoStyled,
  MobileMenuStyled,
  NameEmail,
  RightMenu,
  RightOptionWrapper,
  UserCardWrapper,
  UserProfileImage,
  WrapperHeader,
} from "./styled"

const linksLeftMenu = [
  { label: "Dashboard", link: "/" },
  { label: "Order Blotter", link: "/order-blotter" },
  { label: "Monitoring", link: "/monitoring" },
]

const linksRightMenu = [{ label: "Login", link: "/login" }]

const UserCard: React.FC<{ userData: UserData }> = ({ userData }) => {
  const { firstName, lastName, email, imageUrl } = userData
  return (
    <UserCardWrapper>
      <UserProfileImage
        src={imageUrl ? imageUrl : "icons/user.svg"}
        alt="User icon"
        title="User profile"
      />
      <NameEmail>
        <T.H5>{`${firstName ? firstName : ""} ${
          lastName ? lastName : ""
        }`}</T.H5>
        <T.P3 style={{ color: colors.iMediumGrey }}>{`${email}`}</T.P3>
      </NameEmail>
    </UserCardWrapper>
  )
}

interface MobileHamburgerMenuProps {
  isOpenMobileMenu: boolean
  setIsOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  isLoggedIn: boolean
  logout: () => void
}
const MobileHamburgerMenu: React.FC<MobileHamburgerMenuProps> = ({
  setIsOpenMobileMenu,
  isLoggedIn,
  logout,
}) => {
  const navigate = useNavigate()

  return (
    <MobileHamburgerMenuWrapper>
      <HamburgerHeader>
        <LogoStyled onClick={() => navigate("/")} title="FSpotter" />
        <CloseOutlineStyled onClick={() => setIsOpenMobileMenu(false)} />
      </HamburgerHeader>
      {[
        ...linksLeftMenu,
        { label: "Settings", link: "/settings" },
        ...(!isLoggedIn ? [{ label: "Login", link: "/login" }] : []),
      ].map(({ label, link }) => (
        <MobileHamburgerMenuItem
          key={`item-${label}-hamburger`}
          onClick={() => {
            setIsOpenMobileMenu(false)
            navigate(link)
          }}
          isSelected={window.location.pathname === link}
        >
          {label}
        </MobileHamburgerMenuItem>
      ))}
      {isLoggedIn && (
        <MobileHamburgerMenuItem onClick={() => logout()}>
          Logout
        </MobileHamburgerMenuItem>
      )}
    </MobileHamburgerMenuWrapper>
  )
}

const Header = () => {
  const navigate = useNavigate()
  const pathname = window.location.pathname
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

  const [user, setUser] = useState<User>(defaultLoggedOutUser)
  const { getCurrentUser, logout, isLoggedIn } = useContext(AuthContext)

  useEffect(() => {
    getCurrentUser().then(setUser)
  }, [getCurrentUser, isLoggedIn])

  return (
    <WrapperHeader>
      <LeftMenu>
        <LogoStyled onClick={() => navigate("/")} title="FSpotter" />
        <HeaderPagesLinks>
          {linksLeftMenu.map(({ label, link }) => (
            <HeaderPageLink
              key={`header-${label}-link`}
              onClick={() => navigate(link)}
              isSelected={pathname === link}
            >
              <T.H4>{label}</T.H4>
            </HeaderPageLink>
          ))}
        </HeaderPagesLinks>
      </LeftMenu>

      <RightMenu>
        {user.isLogged ? (
          <>
            <UserCard userData={user.user} />
            <RightOptionWrapper isFirst>
              <CogStyled
                onClick={() => navigate("/settings")}
                title={"Settings"}
              />
            </RightOptionWrapper>
            <RightOptionWrapper isLast>
              <LogoutCircleRStyled onClick={logout} title={"Log out"} />
            </RightOptionWrapper>{" "}
          </>
        ) : (
          <HeaderPagesLinks>
            {linksRightMenu.map(({ label, link }) => (
              <HeaderPageLink
                key={`header-link-${label}`}
                onClick={() => navigate(link)}
                isSelected={pathname === link}
              >
                <T.H4>{label}</T.H4>
              </HeaderPageLink>
            ))}
          </HeaderPagesLinks>
        )}
      </RightMenu>

      <MobileLogoStyled onClick={() => navigate("/")} title={"FSpotter"} />
      <MobileMenuStyled onClick={() => setIsOpenMobileMenu(true)} />
      {isOpenMobileMenu && (
        <MobileHamburgerMenu
          isOpenMobileMenu={isOpenMobileMenu}
          setIsOpenMobileMenu={setIsOpenMobileMenu}
          isLoggedIn={isLoggedIn}
          logout={logout}
        />
      )}
    </WrapperHeader>
  )
}

export default Header
