import { DotsVerticalRounded } from "@styled-icons/boxicons-regular/DotsVerticalRounded"
import { Close } from "@styled-icons/ionicons-outline/Close"
import styled from "styled-components"
import { breakpointNum, colors, widgetLayouts, palette } from "../../styles"
import * as T from "../typography"

export const GraphHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  height: ${widgetLayouts.headerHeightPx}px;
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    height: ${widgetLayouts.headerSmallHeightPx}px;
  }
`

export const CloseIcon = styled(Close)`
  color: ${colors.iMediumGrey};
  width: ${widgetLayouts.headerIconDimPx}px;
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    width: ${widgetLayouts.headerSmallIconDimPx}px;
  }
  cursor: pointer;
  :hover {
    color: ${palette.hover};
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const DotsIcon = styled(DotsVerticalRounded)`
  height: 24px;
  color: ${colors.iMediumGrey};
  :hover {
    color: ${palette.hover};
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
  }
`

export const StyledDropdownDiv = styled.div`
  z-index: 123;
  .ant-dropdown-menu {
    background: ${colors.iBlack};
  }
  .ant-dropdown-menu-item-divider {
    background-color: ${colors.iUltraLightGrey};
    opacity: 0.16;
  }
  .ant-dropdown-menu-item {
    background: ${colors.iBlack};
    gap: 14px;
  }
  .ant-dropdown-menu-item:hover {
    background: ${colors.iUltraDarkGrey};
  }
  .ant-dropdown-menu-item-disabled:hover {
    & * {
      color: ${colors.iRed};
    }
  }
`

export const StyledMenuItemText = styled(T.P3)`
  color: ${colors.iWhite};
`

export const GraphHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const GraphHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  overflow: auto;
`

export const WidgetTitle = styled(T.H5)`
  color: ${colors.iMediumGrey};
`
