const formatter = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
})

export const formatExposure = (exposure: number) =>
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? `${exposure.toFixed(2)} BTC`
    : formatter.format(exposure / 1_000_000) + "m"
