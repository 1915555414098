import _ from "lodash"
import RGL from "react-grid-layout"
import { Socket } from "socket.io-client"
import { z } from "zod"
import { createGraphsFromLocalStorage, getBrokerPair } from "../../helpers"
import { breakpointNum } from "../../styles"
import { GraphsList, GraphsSaved, ReactSetter } from "../../types"

const getDataGridParams = (
  graph: GraphsList[number],
): Omit<RGL.Layout, "x" | "y" | "i"> => {
  const windowWidth = window.innerWidth
  switch (graph.type) {
    case "spread":
      return { w: 18, h: 7, resizeHandles: ["se"], minW: 12, minH: 5 }
    case "spreadCounter":
      return { w: 6, h: 4 }
    case "liquidity":
      if (graph.broker.id === "aggregated") return { w: 7, h: 4 }
      else return { w: 7, h: 3.5 }
    case "TOBContribution":
      return { w: 6, h: 4.5 }
    case "TOBDominance":
      return { w: 5, h: 4 }
    case "openPositions":
      return { w: 9, h: 5 }
    case "balanceEquity":
      return {
        w: 16,
        h: windowWidth > breakpointNum.smalltablet ? 6 : 11.2,
        isResizable: windowWidth > breakpointNum.smalltablet,
        resizeHandles:
          windowWidth > breakpointNum.smalltablet ? ["se"] : undefined,
        minW: 12,
        minH: windowWidth > breakpointNum.smalltablet ? 6 : 11.2,
      }
    case "exposureMargin":
      return {
        w: 16,
        h: windowWidth > breakpointNum.smalltablet ? 6 : 11.2,
        isResizable: true,
        resizeHandles: ["se"],
        minW: 6,
        minH: windowWidth > breakpointNum.smalltablet ? 6 : 11.2,
      }
    case "exposurePairs":
      return {
        w: 16,
        h: windowWidth > breakpointNum.smalltablet ? 6 : 11.5,
        isResizable: true,
        resizeHandles: ["se"],
        minW: 6,
        minH: windowWidth > breakpointNum.smalltablet ? 6 : 11.5,
      }
    case "volumePairs":
      return {
        w: 16,
        h: windowWidth > breakpointNum.smalltablet ? 6 : 11.5,
        isResizable: true,
        resizeHandles: ["se"],
        minW: 6,
        minH: windowWidth > breakpointNum.smalltablet ? 6 : 11.5,
      }
    case "currencyExposure":
      return { w: 11, h: 4 }
    case "pep":
      return { w: 18, h: 10, resizeHandles: ["se"], minW: 12, minH: 8 }
    case "pep-beta":
      return { w: 18, h: 10, resizeHandles: ["se"], minW: 12, minH: 8 }
    case "quotesDelays":
      return { w: 15, h: 8 }
    case "aiActivity":
    case "aiActivity-2":
      return { w: 18, h: 7, resizeHandles: ["se"], minW: 12, minH: 6 }
    case "aiActivityLine":
      return { w: 18, h: 7, resizeHandles: ["se"], minW: 12, minH: 6 }
    case "spreadAverages":
      return { w: 9, h: 7.5 }
    case "costs":
      return { w: 5, h: windowWidth > breakpointNum.smalltablet ? 4.5 : 3.5 }
    case "popOrBrokerTable":
      return { w: 10, h: 8.5 }
    case "externalPrices":
      return { w: 18, h: 7, resizeHandles: ["se"], minW: 5, minH: 5 }
    case "externalEconomicCalendar":
      return { w: 12, h: 7, resizeHandles: ["se"], minW: 5, minH: 5 }
    case "externalMarketOverview":
      return { w: 15, h: 10, resizeHandles: ["se"], minW: 5, minH: 5 }
    case "balanceEquityMini":
      return { w: 5, h: 5 }
    case "cryptoComparison":
      return { w: 9, h: 3 }
  }
}

const findFirstFreeSpot = (
  layouts: RGL.Layout[],
  { w, h }: { w: number; h: number },
  cols: number,
) => {
  let [x, y] = [0, 0]

  const isFree = (theX: number, theY: number, layouts: RGL.Layout[]) =>
    !layouts.some(existingGraph => {
      const tlExisting = [existingGraph.x, existingGraph.y]
      const brExisting = [
        existingGraph.x + Math.min(existingGraph.w, cols),
        existingGraph.y + existingGraph.h,
      ]
      const tlNew = [theX, theY]
      const brNew = [theX + w, theY + h]

      if (tlExisting[0] >= brNew[0] || tlNew[0] >= brExisting[0]) {
        return false
      }
      if (tlExisting[1] >= brNew[1] || tlNew[1] >= brExisting[1]) {
        return false
      }
      return true
    })

  while (true) {
    if (isFree(x, y, layouts)) {
      return { x, y }
    } else {
      const isOverflowing = x + 1 + w > cols
      if (isOverflowing) {
        y = y + 1
        x = 0
      } else {
        x = x + 1
      }
    }
  }
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const computeNewLayoutsOfAddedGraph =
  (layouts: RGL.Layout[], cols: number) =>
  (acc: RGL.Layout[], graph: GraphsList[number]) => {
    const dataGridParams = getDataGridParams(graph)
    return [
      ...acc,
      {
        resizeHandles: [],
        ...dataGridParams,
        i: graph.id,
        ...findFirstFreeSpot([...layouts, ...acc], dataGridParams, cols),
      },
    ]
  }

export const getRooms = (graphsList: GraphsList) =>
  Array.from(
    new Set(
      graphsList.map(
        g => `${g.type}-${getBrokerPair(g.broker, g.pair, g.comm, g.vwap)}`,
      ),
    ),
  )

export const saveLatestGraphsInLocalStorage = (
  graphs: GraphsList,
  updateLatestGraphs: (
    newStorage: GraphsSaved | ((prevState: GraphsSaved) => GraphsSaved),
  ) => void,
) => {
  const rest: GraphsSaved = graphs.map(g => {
    const { Graph, ...rest } = g
    return rest
  })
  updateLatestGraphs(rest)
}

export const restoreGraphsFromLocalStorage = (
  latestLayout:
    | {
        name?: string | undefined
        layouts: RGL.Layout[]
      }
    | undefined,
  latestGraphs: GraphsSaved,
  setGraphs: ReactSetter<GraphsList>,
) => {
  if (
    latestLayout &&
    latestLayout.layouts.length > 0 &&
    latestGraphs.length > 0
  )
    setGraphs(createGraphsFromLocalStorage(latestGraphs))
}

export const addNewGraphsToLayout = (
  graphs: GraphsList,
  layouts: RGL.Layout[],
  setLayouts: ReactSetter<RGL.Layout[]>,
  oldGraphs: GraphsList,
  cols: number,
) => {
  const brandNewGraph = graphs.filter(
    graph => !layouts.some(l => graph.id === l.i),
  )

  const newLayouts = brandNewGraph.reduce(
    computeNewLayoutsOfAddedGraph(layouts, cols),
    [] as RGL.Layout[],
  )

  const removedGraphs = (oldGraphs || []).filter(
    theGraph => !graphs.some(g => g.id === theGraph.id),
  )

  const stillPresentLayout = layouts
    .filter(layout => !removedGraphs.some(graph => graph.id === layout.i))
    .map(actualLayout => {
      const graph = graphs.find(g => g.id === actualLayout.i)
      if (!graph) return actualLayout
      const dataGridParams = getDataGridParams(graph)
      const { minW, minH } = dataGridParams

      // check if the actual layout is valid, that is the actual layout is larger than the min{W,H}.
      const isLayoutValid =
        (minW ?? dataGridParams.w) <= actualLayout.w &&
        (minH ?? dataGridParams.h) <= actualLayout.h
      if (isLayoutValid) return actualLayout

      return {
        ...actualLayout,
        ...dataGridParams,
        w: Math.min(cols, minW ?? dataGridParams.w),
        h: Math.max(actualLayout.h, minH ?? -1, dataGridParams.h),
      }
    })

  const newLayout = [...stillPresentLayout, ...newLayouts]

  if (!_.isEqual(newLayout, layouts)) {
    setLayouts(newLayout)
  }
}

export const handleWindowResize = (
  rglRef: React.RefObject<HTMLDivElement>,
  setCols: ReactSetter<number>,
) => {
  function innerHandleResize() {
    const width = rglRef?.current?.clientWidth || getWindowDimensions().width
    const minWidth = 50
    const newCols = width < 420 ? 1 : Math.floor(width / minWidth)
    setCols(newCols)
  }

  window.addEventListener("resize", innerHandleResize)
  innerHandleResize()

  return () => {
    window.removeEventListener("resize", innerHandleResize)
  }
}

export const updateSubscribedRooms = (
  socket: Socket | undefined,
  graphs: GraphsList,
  oldGraphs: GraphsList,
) => {
  if (!socket) return
  const roomsList = getRooms(graphs)

  const oldRoomsList = getRooms(oldGraphs)

  const newRooms = roomsList.filter(room => !oldRoomsList.includes(room))
  newRooms.forEach(room => socket.emit("subscribe", room))

  const toBeRemovedRooms = oldRoomsList.filter(
    room => !roomsList.includes(room),
  )
  toBeRemovedRooms.forEach(room => socket.emit("unsubscribe", room))
}

export const reorderGraphsOnResize = (
  layouts: RGL.Layout[],
  setLayouts: ReactSetter<RGL.Layout[]>,
  cols: number,
) => {
  const newLayout = layouts.reduce((acc, layout) => {
    return [
      ...acc,
      {
        ...layout,
        ...(layout.x + layout.w > cols
          ? findFirstFreeSpot(acc, { w: layout.w, h: layout.h }, cols)
          : []),
      } as RGL.Layout,
    ]
  }, [] as RGL.Layout[])

  if (!_.isEqual(newLayout, layouts)) {
    setLayouts(newLayout)
  }
}

export const layoutsValidator = z.object({
  name: z.string(),
  layouts: z.any().array(),
})
