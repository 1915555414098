import { Spin } from "antd"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { graphsMenuItems, graphsWithComm } from "../../constants"
import {
  getFirstValidBroker,
  getGraphFromType,
  isBrokerValid,
  typedEntries,
} from "../../helpers"
import { GraphIcon } from "../../styles"
import {
  Broker,
  GraphOptions,
  GraphType,
  GraphsList,
  Pair,
  PairId,
} from "../../types"
import CustomTooltip from "../tooltip"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import { pluckAggregatedBrokers } from "../used-pairs-brokers-context/helpers"
import {
  BrokerOption,
  BrokerOptionGroup,
  CheckboxStyled,
  CloseStyled,
  DropDownStyled,
  LabelPairModalStyled,
  ModalFooter,
  ModalHeader,
  ModalHeaderLeft,
  ModalTitle,
  ModalWrapper,
  PairIdModalStyled,
  PairOptionItem,
  PairSearchBox,
  PairsOptionsList,
  SearchIcon,
  SectionTitle,
  SpinnerWrapper,
  StyledButton,
} from "./styled"

export const AddWidgetModal: React.FC<{
  graphType: GraphType
  graphs: GraphsList
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>
  openModal: GraphType | false
  setOpenModal: React.Dispatch<React.SetStateAction<GraphType | false>>
  className?: string
  options: GraphOptions
}> = ({ graphType, graphs, setGraphs, setOpenModal, className, options }) => {
  const { allPairs, brokersList, isPairValid } = useContext(
    PairsBrokersListContext,
  )

  ////////////////////////
  // PAIRS RELATED CODE //
  ////////////////////////
  const [pairsCheckboxes, setPairsCheckboxes] = useState<
    Partial<Record<PairId, { checked: boolean } & Pair>>
  >(
    allPairs.reduce(
      (acc, pair) => ({
        ...acc,
        [pair.id]: {
          ...pair,
          checked: false,
        },
      }),
      {},
    ),
  )
  const [visiblePairs, setVisiblePairs] = useState(allPairs.map(p => p.id))

  useEffect(() => {
    const usedPairs = allPairs.filter(p => isPairValid(p.id, options))
    setPairsCheckboxes(
      usedPairs.reduce(
        (acc, pair) => ({
          ...acc,
          [pair.id]: {
            ...pair,
            checked: false,
          },
        }),
        {},
      ),
    )
    setVisiblePairs(usedPairs.map(p => p.id))
  }, [allPairs, options, isPairValid])

  const numberAlreadyPresentGraphs = useMemo(
    () => graphs.filter(g => g.type === graphType).length,
    [graphs, graphType],
  )
  const numberCheckedPairs = useMemo(
    () =>
      typedEntries(pairsCheckboxes).filter(([_pair, val]) => val?.checked)
        .length,
    [pairsCheckboxes],
  )
  const maximumNumberOfGraphs = graphsMenuItems.find(
    item => item.graph === graphType,
  )?.maxItems
  const tooManyGraphsOfSameType = maximumNumberOfGraphs
    ? numberAlreadyPresentGraphs + numberCheckedPairs >= maximumNumberOfGraphs
    : false

  //////////////////////////
  // BROKERS RELATED CODE //
  //////////////////////////
  const [aggregatedBroker, nonAggregatedBrokers] =
    pluckAggregatedBrokers(brokersList)
  const filteredNonAggregatedBrokers = nonAggregatedBrokers.filter(b =>
    isBrokerValid(b, options),
  )

  const defaultBroker = getFirstValidBroker(options, brokersList)
  const [selectedBroker, setSelectedBroker] = useState<Broker>(defaultBroker)

  const pairTick = useCallback((pairId: PairId) =>
    setPairsCheckboxes({
      ...pairsCheckboxes,
      [pairId]: {
        ...pairsCheckboxes[pairId],
        checked: !pairsCheckboxes[pairId]?.checked,
      },
    }), [pairsCheckboxes])

  const pairSearch = useCallback((input: string) =>
    setVisiblePairs(
      Object.values(pairsCheckboxes)
        .filter(vp => vp.label.toLowerCase().startsWith(input.toLowerCase()))
        .map(vp => vp.id),
    ), [pairsCheckboxes])

  const createWidget = () => {
    const selectedPairs = Object.values(pairsCheckboxes).filter(
      pl => pl.checked,
    )
    if (selectedPairs.length === 0 && options.pairs.allIndividual) return

    setGraphs([
      ...graphs,
      ...(selectedPairs.length === 0
        ? [
          {
            id: uuidv4(),
            Graph: getGraphFromType(graphType),
            broker: selectedBroker,
            pair: "all" as PairId,
            type: graphType,
            vwap: false,
            comm: graphsWithComm.includes(graphType),
          },
        ]
        : [
          ...selectedPairs.map(selectedPair => ({
            id: uuidv4(),
            Graph: getGraphFromType(graphType),
            broker: selectedBroker,
            pair: selectedPair.id,
            type: graphType,
            vwap: false,
            comm: graphsWithComm.includes(graphType),
          })),
        ]),
    ])
    setOpenModal(false)
  }

  const menuItem = graphsMenuItems.find(gmi => gmi.graph === graphType)
  return (
    <ModalWrapper className={className}>
      <ModalHeader>
        <ModalHeaderLeft>
          <GraphIcon icon={menuItem?.icon} />
          <ModalTitle>{menuItem?.label}</ModalTitle>
        </ModalHeaderLeft>
        <CloseStyled onClick={() => setOpenModal(false)} />
      </ModalHeader>

      <SectionTitle>Choose the broker</SectionTitle>
      <DropDownStyled
        showSearch
        placeholder="Select"
        listHeight={350}
        filterOption={(input, option) =>
          option?.children?.toLowerCase()?.includes(input?.toLowerCase())
        }
        optionFilterProp="children"
        defaultValue={defaultBroker.label}
        disabled={
          !(options.brokers.allIndividualFeeds || options.brokers.tradedBrokers)
        }
        onChange={(selected: unknown) => {
          setSelectedBroker(JSON.parse(selected as string) as Broker)
        }}
      >
        {options.brokers.aggregated && (
          <BrokerOptionGroup label="Consolidated">
            <BrokerOption value={JSON.stringify(aggregatedBroker)}>
              {aggregatedBroker.label}
            </BrokerOption>
          </BrokerOptionGroup>
        )}
        <BrokerOptionGroup label="Brokers">
          {filteredNonAggregatedBrokers.map(broker => (
            <BrokerOption
              key={`${broker.id}-option`}
              value={JSON.stringify(broker)}
            >
              {broker.label}
            </BrokerOption>
          ))}
        </BrokerOptionGroup>
      </DropDownStyled>

      {(options.pairs.allIndividual || options.pairs.onlyUsedForTrading) && (
        <>
          <SectionTitle>Select one or more pairs</SectionTitle>
          <PairSearchBox
            prefix={<SearchIcon />}
            onChange={e => pairSearch(e.target.value)}
            placeholder="Search pairs"
          />
          {visiblePairs.length === 0 && (
            <SpinnerWrapper>
              <Spin />
            </SpinnerWrapper>
          )}
          <PairsOptionsList>
            {visiblePairs.map(pair => (
              <CustomTooltip
                show={
                  tooManyGraphsOfSameType && !pairsCheckboxes[pair]?.checked
                }
                placement={"right"}
                graphType={graphType}
                key={`${pair}-option-tooltip`}
              >
                <PairOptionItem
                  key={`${pair}-option-item`}
                  disabled={
                    tooManyGraphsOfSameType && !pairsCheckboxes[pair]?.checked
                  }
                  onClick={() => {
                    if (
                      tooManyGraphsOfSameType &&
                      !pairsCheckboxes[pair]?.checked
                    )
                      return
                    pairTick(pair)
                  }}
                >
                  <CheckboxStyled
                    value={pair}
                    checked={!!pairsCheckboxes[pair]?.checked}
                    disabled={
                      !pairsCheckboxes[pair]?.checked && tooManyGraphsOfSameType
                    }
                  />
                  <PairIdModalStyled>
                    {pairsCheckboxes[pair]?.label}
                  </PairIdModalStyled>
                  <LabelPairModalStyled>
                    {pairsCheckboxes[pair]?.fullName}
                  </LabelPairModalStyled>
                </PairOptionItem>
              </CustomTooltip>
            ))}
          </PairsOptionsList>
        </>
      )}

      <ModalFooter>
        <StyledButton type="primary" onClick={() => createWidget()}>
          Create widget{" "}
        </StyledButton>
        <StyledButton onClick={() => setOpenModal(false)}>Cancel</StyledButton>
      </ModalFooter>
    </ModalWrapper>
  )
}
