import RGL, { WidthProvider } from "react-grid-layout"
import styled from "styled-components"
import ButtonAddWidget from "../../components/button-add-widget"
import * as T from "../../components/typography"
import { ReactComponent as QuestionDice } from "../../icons/block-question-thin.svg"
import { ReactComponent as DownArrow } from "../../icons/empty-dashboard-arrow.svg"
import { breakpoint, colors, palette } from "../../styles"

export const ItemWrapper = styled.div`
  z-index: 2;
`

export const EmptyCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const GreyCircle = styled.div`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 100%;
  width: 395px;
  height: 395px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${breakpoint.mobile}) {
    width: 250px;
    height: 250px;
  }
`

export const EmptyDashboardLogo = styled.div`
  padding: 62px 0 0 0;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DiceStyled = styled(QuestionDice)`
  color: ${colors.iBlack};
  width: 300px;

  @media (max-width: ${breakpoint.mobile}) {
    width: 200px;
  }

  z-index: 1;
`

export const EmptyPageTitle = styled(T.H2)`
  text-align: center;
  z-index: 1;
  max-width: 348px;
  line-height: 1.5em;
`

export const EmptyPageSubTitle = styled(T.P2)`
  text-align: center;
  z-index: 1;
  max-width: 494px;
  color: ${colors.iMediumGrey};
  line-height: 1.5em;
`

export const Arrow = styled(DownArrow)`
  margin-right: 4px;
  margin-top: 100px;
  color: ${palette.primary};
  @media (max-width: ${breakpoint.mobile}) {
    margin-top: 92px;
    width: 40px;
  }
`

export const AddContentText = styled(T.H2)`
  color: ${palette.primary};
  font-family: Patrick-Hand-Regular;
  font-size: 30px;
  padding-top: 75px;
  text-align: right;
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 25px;
    text-align: center;
  }
`

export const EmptyBottomLeft = styled.div`
  position: fixed;
  bottom: 0%;
  padding-bottom: 74px;
  padding-left: 74px;
  display: flex;
  @media (max-width: ${breakpoint.mobile}) {
    padding-bottom: 42px;
    padding-left: 60px;
  }
`

export const ButtonAddWidgetStyled = styled(ButtonAddWidget)`
  position: fixed;
  bottom: 0%;
  padding-bottom: 73px;
  padding-left: 16px;
  @media (max-width: ${breakpoint.mobile}) {
    padding-bottom: 60px;
    padding-left: 16px;
  }
`

const ResponsiveGridLayout = WidthProvider(RGL)

export const RGLStyled = styled(ResponsiveGridLayout)<{ width: number }>`
  margin: 8px 0;
  position: relative;
  width: ${({ width }) => width}px;
  > .react-grid-placeholder {
    background-color: ${colors.iUltraDarkGrey}6b;
    z-index: 1;
  }
`

export const RGLWrapper = styled.div`
  display: flex;
  justify-content: center;

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    color: red;
    fill: blue;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid ${colors.iBlue03};
    border-bottom: 2px solid ${colors.iBlue03};
  }

  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
`
