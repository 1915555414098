import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts/highstock"
import React from "react"
import { colors } from "../../styles"
import { formatExposure, tooltipPointFormat } from "./helpers"

type ExposurePairMemoizedGraphProps = {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
}

const ExposurePairMemoizedGraph = ({
  chartComponentRef,
}: ExposurePairMemoizedGraphProps) => (
  <HighchartsReact
    highcharts={Highcharts}
    constructorType={"StockChart"}
    options={{
      credits: {
        enable: false,
      },
      accessibility: {
        enabled: false,
      },
      boost: {
        allowForce: true,
        enabled: true,
      },
      chart: {
        backgroundColor: colors.iUltraDarkGrey,
        spacingLeft: 0,
        spacingRight: 0,
      },

      navigator: {
        enabled: true,
        height: 25,
      },
      rangeSelector: {
        adaptToUpdatedData: true,
        enabled: true,
        stickToMax: true,
        floating: false,
        inputEnabled: true,
        inputPosition: {
          align: "right",
        },
        buttons: [
          {
            type: "minute",
            count: 30,
            text: "30m",
          },
          {
            type: "hour",
            count: 1,
            text: "1h",
          },
          {
            type: "hour",
            count: 6,
            text: "6h",
          },
          {
            type: "hour",
            count: 12,
            text: "12h",
          },
          {
            type: "day",
            count: 1,
            text: "1d",
          },
          {
            type: "day",
            count: 2,
            text: "2d",
          },
          {
            type: "all",
            text: "All",
          },
        ],
        selected: 0,
      },
      scrollbar: {
        enabled: true,
      },

      xAxis: {
        gridLineWidth: 0,
        lineColor: colors.iDarkGrey,
        tickWidth: 0,
        type: "datetime",
        ordinal: false,
      },

      yAxis: [
        {
          labels: {
            formatter: (ctx: Highcharts.AxisLabelsFormatterContextObject) => {
              const label = ctx.axis.defaultLabelFormatter.call(ctx)
              return formatExposure(label)
            },
          },
          opposite: false,
          endOnTick: false,
          gridLineColor: colors.iDarkGrey,
          plotLines: [
            {
              value: 0,
              color: colors.iGrey01,
              width: 1,
              zIndex: 5,
            },
          ],
        },
        {
          labels: {
            formatter: (ctx: Highcharts.AxisLabelsFormatterContextObject) => {
              const label = ctx.axis.defaultLabelFormatter.call(ctx)
              return formatExposure(label)
            },
          },
          gridLineColor: colors.iDarkGrey,
          linkedTo: 0,
          endOnTick: false,
          opposite: true,
        },
      ],
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: false,
          },
          boostThreshold: 1,
          animation: false,
          enableMouseTracking: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      tooltip: {
        useHtml: true,
        backgroundColor: colors.iBlack,
        borderColor: colors.iBlack,
        headerFormat: '<span style="color:#e9e9ec;"><b>{point.key}</b></span>',
        pointFormat: tooltipPointFormat,
        valueDecimals: 3,
        shared: true,
        xDateFormat: "%a, %b %e, %H:%M",
        split: true,
      },
    }}
    ref={chartComponentRef}
    containerProps={{
      className: "chartContainer",
      style: {
        width: "100%",
        height: "100%",
      },
    }}
  />
)

export default React.memo(ExposurePairMemoizedGraph)
