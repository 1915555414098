import styled from "styled-components"
import { ReactComponent as AddWidget } from "../../icons/button-add-widget.svg"
import { breakpoint, colors, palette } from "../../styles"
import * as T from "./../typography"
import { AddWidgetModal } from "../add-widget-modal"

export const AddWidgetButton = styled(AddWidget)<{ $isMenuOpen: boolean }>`
  transition: all 0.4s ease-out;
  transform: ${props => (props.$isMenuOpen ? `rotate(135deg)` : "")};
  color: ${palette.primary};
  cursor: pointer;
  :hover {
    * {
      color: ${palette.hover};
    }
  }
  position: fixed;
  z-index: 12;
  @media (max-width: ${breakpoint.mobile}) {
    width: 40px;
  }
`

export const AddWidgetButtonWrapper = styled.div`
  z-index: 13;
  position: fixed;
`

export const AddWidgetMenuWrapperModalGrid = styled.div`
  position: fixed;
  top: 93%;
  left: 50%;
  width: auto;
  height: auto;
  background-color: ${colors.iUltraDarkGrey};
  display: grid;
  padding: 15px;
  row-gap: 5px;
  column-gap: 5px;
  transform: translate(-50%, -93%);
  grid-template-columns: 1fr 1fr 1fr;
  border: 2px solid ${colors.iWhite};
  border-radius: 4px;
`

export const MenuItemWrapper = styled.div<{
  isFirst?: boolean
  disabled?: boolean
}>`
  border-right: 1px solid ${colors.iDarkGrey};
  :nth-child(3n) {
    border-right: none;
  }
  flex-shrink: 0;
  width: 152px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  :hover {
    opacity: 0.7;
    * {
      color: ${({ disabled }) => (disabled ? colors.iRed : palette.hover)};
      transition: all 0.4s ease-out;
    }
    .MenuItemLabel {
      color: ${({ disabled }) => (disabled ? colors.iRed : colors.iWhite)};
      transition: all 0.4s ease-out;
    }
  }
  @media (max-width: ${breakpoint.mobile}) {
    width: 100px;
    height: 50px;
  }
`

export const MenuItemLabel = styled(T.P3)`
  color: ${colors.iMediumGrey};
  font-size: 11px;
  padding-top: 7px;
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 9px;
  }
`

export const AddWidgetModalStyled = styled(AddWidgetModal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
