import Auth from "./components/auth-context"
import Router from "./router"
import { ConfigProvider } from "antd"
import { palette } from "./styles"

function App() {
  return (
    <Auth>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimaryHover: palette.hover,
              colorPrimaryBgHover: palette.hover,
              colorPrimaryActive: palette.focus,
            },
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </Auth>
  )
}

export default App
